import React, { useEffect, useState } from "react";
import { HiMenu } from "react-icons/hi";
import {
  RiDashboardLine,
  RiUserSettingsLine,
  RiSettings4Line,
  RiMessage3Line,
  RiArticleLine,
  RiServerLine,
} from "react-icons/ri";
import { SiDatabricks } from "react-icons/si";
import { BsCreditCard, BsCalendar2Week } from "react-icons/bs";
import { CgComponents } from "react-icons/cg";
import { Link, useHistory, useLocation } from "react-router-dom";
import { userLogout } from "../../functions/api/logout";
import { getUser } from "../../functions/api/user";
import { makeStyles } from "@material-ui/core/styles";
import Config from "../../Config";
import { Avatar, FormControl, MenuItem } from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import { GrFormAdd } from "react-icons/gr";
import PopUp from "../PopUp/PopUp";
import AddNewWorkSpace from "../PopUp/Containers/AddNewWorkSpace";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import LogoutIcon from '@mui/icons-material/Logout';
import {
  getUserConfig,
  getUserConfigForWorkspace,
} from "../../functions/api/userConfig";
import { getWorkspace } from "../../functions/api/workspace";
import { exportSetWorspacePermission } from "../../App";
import { getWhiteLabel } from "../../functions/api/whiteLabel";
import DeviceNotPaid from "../PopUp/Containers/DeviceNotPaid";

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export var exportUpdateWorkspaces;
export var exportUpdateDevice;
export var exportUpdateSiteProperties;

function Navbar() {
  const location = useLocation();
  const history = useHistory();
  const classes = useStyles();
  const state = history.location.state || {};

  const [ActiveLink, setActiveLink] = useState("");
  const [WorkSpaceValue, setWorkSpaceValue] = useState("");
  const [Menu, setMenu] = useState(false);
  const [DeviceNotPaidStatus, setDeviceNotPaidStatus] = useState(false);
  const [UserConfig, setUserConfig] = useState([]);
  const [NavProfileState, setNavProfileState] = useState(false);
  const [NewWorkspaceState, setNewWorkspaceState] = useState(false);
  const [ImageUrl, setImageUrl] = useState("");
  const [WorkSpaceName, setWorkSpaceName] = useState("W");
  const [UserWorkspacePermission, setUserWorkspacePermission] = useState({
    addDevices: false,
    deviceConfigEdit: false,
    layoutEdit: false,
    widgetsEdit: false,
    notificationEdit: false,
    reportEdit: false,
    notebookEdit: false,
    cellularEdit: false,
  });
  const [UserPermission, setUserPermission] = useState({
    workspaceEdit: false,
    mqttConfigEdit: false,
  });
  const [WhiteLabel, setWhiteLabel] = useState(false);
  const PathList = [
    "workspace",
    "members",
    "notifications",
    "reports",
    "devices",
    "billing",
    "home",
    "calendar",
    "cellular",
    "my-data-sources", //mydatasources
  ];
  const [ImageTag, setImageTag] = useState(true);
  const [Authorized, setAuthorized] = useState(true);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [ProfileImageUrl, setProfileImageUrl] = useState("/images/person.png");

  useEffect(() => {
    getUser((data) => {
      if (data) setProfileImageUrl(`${Config.server + data.data.image}`);
      if (data) setUserPermission(data.data.permission);
    });

    if (window.origin === Config.origin) {
      setWhiteLabel(true);
    } else {
      setWhiteLabel(false);
    }
  }, []);

  useEffect(() => {
    // HanldeOnResize();

    window.addEventListener("click", (e) => handleOnClickListner(e));
    // window.addEventListener("resize", () => HanldeOnResize());

    fetchUser();

    fetchUserConfig();

    setSiteProperties();

    return () => {
      window.removeEventListener("click", (e) => handleOnClickListner(e));
      // window.removeEventListener("resize", () => HanldeOnResize());
    };
  }, []);

  function fetchUser() {
    let nav_profile = document.getElementById("nav_profile_image");

    getUser((data) => {
      if (data.data.image) {
        nav_profile.src = Config.server + data.data.image;
      }
    });
  }




  useEffect(() => {

  
    let isMounted = true;
    let pathname = history.location.pathname.split("/")[1];

    if (isMounted) {
      if (WorkSpaceValue) {
        if (PathList.includes(pathname)) {
          history.push({
            pathname: pathname,
            state: {
              workspace: WorkSpaceValue,
            },
          });
        }

        getUserConfigForWorkspace(WorkSpaceValue, (data) => {
          if (isMounted) if (data) setUserWorkspacePermission(data.permission);
          if (data) exportSetWorspacePermission(data.permission);
        });

        fetchWorkspaceSettings();

        if (!UserPermission.workspaceEdit) {
          fetchWorkspaceGroupId()
        }
        else {
          setAuthorized(true)
        }
      } else {
        setWorkSpaceName("W");
      }
    }
    return () => {
      isMounted = false;
    };
  }, [WorkSpaceValue, Authorized]);

  function fetchWorkspaceSettings() {
    getWorkspace(WorkSpaceValue, (data) => {
      setWorkSpaceName("");

      if (!data.logo) {
        setWorkSpaceName(data.name);
      } else {
        setImageUrl(Config.server + data.logo);
      }
    });
  }

  function fetchWorkspaceGroupId() {
    getUser((data) => {
      let groupId = data.data.groupId

      getWorkspace(WorkSpaceValue, (currentData) => {
        if (groupId !== currentData.groupId) {
          setAuthorized(false)
        }
        else {
          setAuthorized(true)
        }
      })
    })
  }

  function HanldeOnResize() {
    if (window.innerWidth < 770) {
      setMenu(false);
    } else {
      setMenu(true);
    }
  }

  const handleOnWorkspaceChange = (event) => {
    let value = event.target.value;
    let pathname = history.location.pathname.split("/")[1];

    if (window.innerWidth > 770) {
      setMenu(false);
      document.getElementById("content").style.marginLeft = "0px";
    }

    if (value === "new_workspace") {
      handleOnNewWorkSpaceStateChange(true);
    } else {
      if (!PathList.includes(pathname)) {
        history.push({
          pathname: "/devices",
          state: {
            workspace: value,
          },
        });
      }

      setWorkSpaceValue(value);
    }
  };

  function handleOnNewWorkSpaceStateChange(state) {
    setNewWorkspaceState(state);
    fetchUserConfig();
  }

  function fetchUserConfig() {
    getUserConfig((data) => {
      setUserConfig(data);

      if (data.length !== 0) {
        let exist = data.find((i) => i.id === data.id);

        if (exist) {
          fetchWorkspaceSettings();
        }
      }
    });
  }

  exportUpdateWorkspaces = () => {
    fetchUserConfig();
  };

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      let emptyVals = ["undefined", "false", undefined, null, false, NaN, ""];
      let locationPathName = location.pathname.split("/");
      let name = locationPathName[1];

      if (
        !name ||
        name === "device" ||
        name === "configure" ||
        name === "notebook"
      ) {
        name = "devices";
      }

      if (name === "new-notification" || name === "edit-notification") {
        name = "notifications";
      }

      if (name === "new-report" || name === "edit-report") {
        name = "reports";
      }

      if (name === "white-label") name = "whitelabel";

      if (name === "cellular-sim") name = "cellular";

      setActiveLink(name);

      if (emptyVals.includes(state.workspace)) {
        UserConfig.length !== 0
          ? setWorkSpaceValue(UserConfig[0].workspace)
          : setWorkSpaceValue("");
      } else {
        setWorkSpaceValue(state.workspace);
      }
    }

    return () => {
      isMounted = false;
    };
  }, [location, UserConfig]);

  function handleNavLinkClick(e) {
    let name = e.target.id.split("-")[1];
    setActiveLink(name);
    // handleOnMenuClick()
    if (window.innerWidth > 770) {
      setMenu(false);
    }
  }

  //  𝘀𝗶𝗱𝗲𝗻𝗮𝘃 𝗯𝗮𝗿 𝗵𝗮𝗻𝗱𝗹𝗲 𝗿𝗲𝘀𝗽𝗼𝘀𝗻𝘀𝗲𝗱⁡

  function handleOnMenuClick() {
    let menu = !Menu;
    setMenu(menu);
   

    if (document.getElementById("content")) {
      if (!Menu) {
        document.getElementById("content").style.marginLeft = "280px";
       
      }
      else {
        document.getElementById("content").style.marginLeft = "0px";
      
      }
    }
  }

  function handleOnClickListner(e) {
    if (e.target.id !== "nav_profile_image") {
      setNavProfileState(false);
    }
  }

  function handleOnLogout() {
    userLogout();
  }

  function handleOnProfileClick() {
    setNavProfileState(!NavProfileState);
  }

  exportUpdateSiteProperties = () => {
    setSiteProperties();
  };

  function setSiteProperties() {
    let domain = window.location.hostname;

    getWhiteLabel(domain, (data) => {

      if (data) {
        let nav_logo = document.getElementById("nav_top_logo");
        let navbar = document.getElementById("nav_top");

        if (data.siteTitle) {
          document.title = data.siteTitle;
        } else {
          document.title = "Industrial Dashboard";
        }

        //icon
        let linkIcon = document.querySelector("link[rel~='icon']");
        if (!linkIcon) {
          linkIcon = document.createElement("link");
          linkIcon.rel = "icon";
          document.getElementsByTagName("head")[0].appendChild(linkIcon);
        }

        //apple icon
        let linkAppleIcon = document.querySelector(
          "link[rel~='apple-touch-icon']"
        );
        if (!linkAppleIcon) {
          linkAppleIcon = document.createElement("link");
          linkAppleIcon.rel = "icon";
          document.getElementsByTagName("head")[0].appendChild(linkAppleIcon);
        }

        if (data.siteIcon) {
          linkAppleIcon.href = linkIcon.href = Config.server + data.siteIcon;
        } else {
          linkAppleIcon.href = linkIcon.href = "/logo.png";
        }

        if (data.siteLogo) {
          setImageTag(
            <img
              alt="logo"
              className="nav_top_title"
              id="nav_top_logo"
              src={Config.server + data.siteLogo}
            />
          )
        } else {
          setImageTag(
            <img
              alt="logo"
              className="nav_top_title"
              id="nav_top_logo"
              src="/logo.png"
            />
          )
        }

        if (data.siteColor) {
          if (navbar) navbar.style.backgroundColor = data.siteColor;
        } else {
          if (navbar) navbar.style.backgroundColor = "#000000";
        }
      } else {
        document.title = "Industrial Dashboard";
        //icon
        let linkIcon = document.querySelector("link[rel~='icon']");
        if (!linkIcon) {
          linkIcon = document.createElement("link");
          linkIcon.rel = "icon";
          document.getElementsByTagName("head")[0].appendChild(linkIcon);
        }

        //apple icon
        let linkAppleIcon = document.querySelector(
          "link[rel~='apple-touch-icon']"
        );
        if (!linkAppleIcon) {
          linkAppleIcon = document.createElement("link");
          linkAppleIcon.rel = "icon";
          document.getElementsByTagName("head")[0].appendChild(linkAppleIcon);
        }

        let nav_logo = document.getElementById("nav_top_logo");
        let navbar = document.getElementById("nav_topnav_top");
        linkAppleIcon.href = linkIcon.href = "/logo.png";
        setImageTag(
          <img
            alt="logo"
            className="nav_top_title"
            id="nav_top_logo"
            src="/logo.png"
          />
        )
        if (navbar) navbar.style.backgroundColor = "#423F3E";
      }
    });
  }

  function handleDeviceNotPaidStatus(state) {
    setDeviceNotPaidStatus(state);
  }

  return (
    <div id="navbar">
      {/* nav top start */}

      {/* nav top end */}

      <div className="nav_body_main">
        {/* <button className="nav_slider_button" onClick={handleOnMenuClick}>
          {Menu ? <KeyboardArrowLeftIcon /> : <KeyboardArrowRightIcon />}
        </button> */}
        {Menu ? (
          <div className="nav_body" data-active={true}>
            {/* <HiMenu id="nav_top_menu_icon" onClick={handleOnMenuClick} />{" "} */}

            {/* <div className="nav_top_grid_item">
              <img
                src="/images/person.png"
                alt="person"
                id="nav_profile_image"
                className="nav_profile_image"
                onClick={() => handleOnProfileClick()}
              />
              <div id="nav_profile_wrapper" data-active={NavProfileState}>
                <Link
                  to={{
                    pathname: "/profile",
                    state: { workspace: WorkSpaceValue },
                  }}
                  id="nav_link-profile"
                  onClick={(e) => handleNavLinkClick(e)}
                  className="nav_profile_item"
                >
                  Profile
                </Link>
              </div>
            </div> */}
            {/* nav slider start */}
            <div className="nav_slider" data-active={true} id="mySidenav">
              <div className="nav_slider_in_wrap">

                {/* side navbar workspace name hide and responsive */}
                <div className="nav_dashboards_wrap">

                  <div className="nav_top" id="nav_top">
                    <div className="nav_top_grid_item">
                      <div id="nav_top_logo">
                        <Link to={{ pathname: "/devices", state: { workspace: WorkSpaceValue } }}>
                          {ImageTag}
                        </Link>
                      </div>
                    </div>
                  </div>

                  <div id="nav_profile_img_wrapper" data-active={NavProfileState}>
                    <div className="image_wrapper">
                      <Link
                        to={{
                          pathname: "/profile",
                          state: { workspace: WorkSpaceValue },
                        }}
                        id="nav_link-profile"
                        onClick={(e) => handleNavLinkClick(e)}
                        className="nav_profile_item_img"
                      >
                        <img
                          src={ProfileImageUrl}
                          alt="person"
                          id="nav_profile_image"
                          className="nav_profile_image"
                          style={{ width: "70px", height: "70px", borderRadius: "50%", border: "1px solid $dark" }} />
                      </Link>
                    </div>
                  </div>
                  <h3 className="title">Workspaces</h3>
                  <div className="nav_dashboards_wrapper">
                    {WorkSpaceName ? (
                      <Avatar>{WorkSpaceName.charAt(0)}</Avatar>
                    ) : (
                      <Avatar src={ImageUrl}></Avatar>
                    )}
                    <FormControl variant="filled" className={classes.formControl}>
                      <InputLabel id="demo-simple-select-filled-label">
                        Select
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-filled-label"
                        id="demo-simple-select-filled"
                        value={WorkSpaceValue}
                        onChange={handleOnWorkspaceChange}
                      >
                        {UserConfig.map((item) => {
                          return (
                            <MenuItem key={item.id} value={item.workspace}>
                              {item.workspace_data.name}
                            </MenuItem>
                          );
                        })}
                        <MenuItem
                          value="new_workspace"
                          style={{ borderTop: "1px solid rgba(0, 0, 0, 0.3)" }}
                        >
                          <GrFormAdd className="nav_workspace_menu_icons" /> New
                          Workspace
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                </div>

                <Link
                  to={{ pathname: "/home", state: { workspace: WorkSpaceValue } }}
                  id="nav_link-home"
                  className="nav_slider_link"
                  data-active={ActiveLink === "home" ? "true" : "false"}
                  onClick={(e) => handleNavLinkClick(e)}
                >
                  <CgComponents className="icon" />
                  <span>Global Dashboard</span>
                </Link>

                {/* <Link
                to={{
                  pathname: "/devices",
                  state: { workspace: WorkSpaceValue },
                }}
                id="nav_link-devices"
                className="nav_slider_link"
                data-active={ActiveLink === "devices" ? "true" : "false"}
                onClick={(e) => handleNavLinkClick(e)}
              >
                <RiDashboardLine className="icon" />
                <span>Data Sources</span>
              </Link> */}

                {/* My Dashboard */}
                <Link
                  to={{
                    pathname: "/my-data-sources",
                    state: { workspace: WorkSpaceValue },
                  }}
                  id="nav_link-devices"
                  className="nav_slider_link"
                  data-active={ActiveLink === "my-data-sources" ? "true" : "false"}
                  onClick={(e) => handleNavLinkClick(e)}
                >
                  <SiDatabricks className="icon" />
                  <span>Data Sources</span>
                </Link>

                <Link
                  to={{
                    pathname: "/calendar",
                    state: { workspace: WorkSpaceValue },
                  }}
                  id="nav_link-calendar"
                  className="nav_slider_link"
                  data-active={ActiveLink === "calendar" ? "true" : "false"}
                  onClick={(e) => handleNavLinkClick(e)}
                >
                  <BsCalendar2Week className="icon" />
                  <span>Calendar</span>
                </Link>
                {/* <Link
                to={{
                  pathname: "/billing",
                  state: { workspace: WorkSpaceValue },
                }}
                id="nav_link-billing"
                className="nav_slider_link"
                data-active={ActiveLink === "billing" ? "true" : "false"}
                onClick={(e) => handleNavLinkClick(e)} onLoad="loadresponsive()"
              >
                <BsCreditCard className="icon" />
                <span>Billing</span>
              </Link> */}
                <a
                  href="https://cloud.casi.io/billing"
                  className="nav_slider_link"
                >
                  <BsCreditCard className="icon" />
                  <span>Billing</span>
                </a>
                {WhiteLabel ? (
                  <Link
                    to={{
                      pathname: "/white-label",
                      state: { workspace: WorkSpaceValue },
                    }}
                    id="nav_link-whitelabel"
                    className="nav_slider_link"
                    data-active={ActiveLink === "whitelabel" ? "true" : "false"}
                    onClick={(e) => handleNavLinkClick(e)}
                  >
                    <RiServerLine className="icon" />
                    <span>White-Label</span>
                  </Link>
                ) : null}
                <Link
                  to={{
                    pathname: "/members",
                    state: { workspace: WorkSpaceValue },
                  }}
                  id="nav_link-members"
                  className="nav_slider_link"
                  data-active={ActiveLink === "members" ? "true" : "false"}
                  onClick={(e) => handleNavLinkClick(e)}
                  data-disabled={UserConfig.length !== 0 ? false : true}
                >
                  <RiUserSettingsLine className="icon" />
                  <span>Members</span>
                </Link>
                {Authorized ? (
                  <Link
                    to={{
                      pathname: "/workspace",
                      state: { workspace: WorkSpaceValue },
                    }}
                    id="nav_link-workspace"
                    className="nav_slider_link"
                    data-active={ActiveLink === "workspace" ? "true" : "false"}
                    onClick={(e) => handleNavLinkClick(e)}
                    data-disabled={UserConfig.length !== 0 ? false : true}
                  >
                    <RiSettings4Line className="icon" />
                    <span>Workspace</span>
                  </Link>
                ) : (null)}

                {UserWorkspacePermission.notificationEdit ? (
                  <Link
                    to={{
                      pathname: "/notifications",
                      state: { workspace: WorkSpaceValue },
                    }}
                    id="nav_link-notifications"
                    className="nav_slider_link"
                    data-active={ActiveLink === "notifications" ? "true" : "false"}
                    onClick={(e) => handleNavLinkClick(e)}
                    data-disabled={UserConfig.length !== 0 ? false : true}
                  >
                    <RiMessage3Line className="icon" />
                    <span>Notifications</span>
                  </Link>
                ) : null}
                {UserWorkspacePermission.reportEdit ? (
                  <Link
                    to={{
                      pathname: "/reports",
                      state: { workspace: WorkSpaceValue },
                    }}
                    id="nav_link-reports"
                    className="nav_slider_link"
                    data-active={ActiveLink === "reports" ? "true" : "false"}
                    onClick={(e) => handleNavLinkClick(e)}
                    data-disabled={UserConfig.length !== 0 ? false : true}
                  >
                    <RiArticleLine className="icon" />
                    <span>Reports</span>
                  </Link>



                ) : null}

                <button className="nav_slider_link_logout" onClick={(e) => handleOnLogout(e)}>
                  <LogoutIcon className="icon" />
                  <span>Logout</span>
                </button>

              </div>
            </div>
            {/* nav slider end */}
          </div>

        ) : null}

      </div>
      <main className="nav_main"></main>

      <PopUp
        title="New WorkSpace"
        state={NewWorkspaceState}
        onStateChange={(state) => handleOnNewWorkSpaceStateChange(state)}
      >
        <AddNewWorkSpace
          onStateChange={(state) => handleOnNewWorkSpaceStateChange(state)}
        />
      </PopUp>

      <PopUp
        title="Payment Expired"
        state={DeviceNotPaidStatus}
        onStateChange={(state) => handleDeviceNotPaidStatus(state)}
      >
        <DeviceNotPaid
          onClick={(status) => handleDeviceNotPaidStatus(status)}
        />
      </PopUp>
    </div>
  );
}

export default Navbar;
