import React, { useEffect, useState } from "react";
import { createWorkspace } from "../../../functions/api/workspace";

function AddNewWorkSpace({ onStateChange }) {
  const [Name, setName] = useState(false);

  function handleOnClick(e) {
    let name = document.getElementById("anws_workspaceName");

    e.target.dataset.active = "false";
    if (name.value) {
        createWorkspace(name.value, (status) => {
            if (status === "success") {
              name.value = "";
              onStateChange(false);
            }
            e.target.dataset.active = "true";
          });
    }
    else{
        createWorkspace(Name, (status) => {
            if (status === "success") {
              name.value = "";
              setName("")
              onStateChange(false);
            }
            e.target.dataset.active = "true";
          });
    }
   
  }

  function handleOnNameChange(e) {
    setName(e.target.value)
  }

  useEffect(() => {
    let button = document.getElementById("anws-submit_btn");

    window.addEventListener("keydown", handleOnKeyDown);

    function handleOnKeyDown(e) {
      let key = e.code;

      if (key === "Enter") {
        let name = document.getElementById("anws_workspaceName");
        if (name) {
          if (name.value !== "") {
            button.click();
          }
        }
      }
    }

    return () => {
      window.removeEventListener("keydown", handleOnKeyDown);
    };
  }, []);

  return (
    <div id="addNewWorkSpace">
      <p className="title">Name</p>
      <input id="anws_workspaceName" type="text" className="input" onChange={handleOnNameChange}/>
      <button
        id="anws-submit_btn"
        onClick={(e) => handleOnClick(e)}
        className="button"
      >
        Create
      </button>
    </div>
  );
}

export default AddNewWorkSpace;
