import React, { useEffect, useRef, useState } from 'react'
import { FaTimes } from 'react-icons/fa'
import uuid from 'react-uuid'

export var exportPopUpClose;
export var exprotPopUpBackDrop;

function PopUp({title, onStateChange, state, children, isMobile}) {

    const [Key, setKey] = useState('')
    const containerRef = useRef(null);

    exportPopUpClose = () => {
        onClose()
    }
    


    function onClose() {
        onStateChange(false)
    }

    useEffect(() => {
        let isMounted = true
        if(isMounted) {
            if(state){
                setKey(uuid())
            }
        }
        return () => {
            isMounted = false
        }
    }, [state])

   function getScrollTop () {

       if (containerRef.current && title=="FacePlate") {
            return containerRef.current.scrollTop
        }

    return 0;
  };

    return (
        <div key={Key} className="popUpContainer container" data-active={state} data-mobile={isMobile}
        ref={containerRef}
        >
            <div className="popup_container"  style={title === "FacePlate" ? { padding: '0px' } : {}}>
               {title!="FacePlate"&& <div className="popup_top" style={title === "FacePlate" ? { padding: '0px' } : {}}>
                    <h3 className="popup_title">{title}</h3>
                    <FaTimes className="popup_close_icon" onClick={() => {onClose()}}/>
                </div>}
                <div className="popup_body" style={title === "FacePlate" ? { padding: '0px' } : {}}>
                {React.Children.map(children, child =>
                        React.cloneElement(child, { getScrollTop })
                    )}
                    {/* {children} */}
                </div>
            </div>
        </div>
    )
}

export default PopUp
