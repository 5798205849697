import React from 'react'

function PreLoading() {
    return (
        <div id="preLoader">
            <div className="circle"></div>
        </div>
    )
}

export default PreLoading
