import { useEffect } from 'react';

const useDisablePasswordAutofill = () => {
  useEffect(() => {
    const disableAutofill = (input) => {
      input.setAttribute('autocomplete', 'off');
      input.setAttribute('autofill', 'off');
      const name = input.getAttribute('name');
      if (name) {
        input.setAttribute('name', `new-${name}`);
      }

      const dummyField = document.createElement('input');
      dummyField.setAttribute('type', 'text');
      dummyField.setAttribute('style', 'display:none');
      dummyField.setAttribute('aria-hidden', 'true');
      input.parentNode.insertBefore(dummyField, input);
    };

    const allInputs = document.querySelectorAll('input, textarea, select');
    allInputs.forEach(disableAutofill);
  }, []);
};

export default useDisablePasswordAutofill;
