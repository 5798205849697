import { server } from "../../Config";
import { notify } from "../notify";
import axios from "axios";
import { userLogout } from "./logout";

export async function getUser(callback) {
  let user = window.localStorage.getItem("gas-web-user");

  if (!user) {
    return;
  }

  axios({
    method: "POST",
    url: server + "api/getUser",
    withCredentials: true,
    data: {
      email: user,
    },
  })
    .then((res) => {
      if (!res.data.data) {
        notify("info", "No records");
        return;
      }

      callback(res.data.data);
    })
    .catch((err) => {
      if (err.response) {
        if (err.response.data.message) {
          notify("error", err.response.data.message);
          if (err.response.data.message === "user not found") {
            userLogout();
          }
        }
      }
    });
}

export async function getUserByGroupId(groupId, callback) {
  axios({
    method: "POST",
    url: server + "api/getUserByGroupId",
    withCredentials: true,
    data: {
      groupId: groupId,
    },
  })
    .then((res) => {
      if (!res.data.data) {
        notify("info", "No records");
        return;
      }

      callback(res.data.data);
    })
    .catch((err) => {
      if (err.response) {
        if (err.response.data.message) {
          notify("error", err.response.data.message);
          if (err.response.data.message === "user not found") {
            userLogout();
          }
        }
      }
    });
}

export async function updateUserImage(image, callback) {
  let user = window.localStorage.getItem("gas-web-user");

  let data = new FormData();

  data.append("email", user);
  data.append("image", image.files[0]);

  axios({
    method: "PATCH",
    url: server + "api/updateUserImage",
    data: data,
    withCredentials: true,
  })
    .then((res) => {
      if (res.data.status === "success") {
        notify(res.data.status, res.data.message);
      }
      callback(res.data.status);
    })
    .catch((err) => {
      callback("failed");
      console.log(err);
      if (err.response) {
        if (err.response.data.message) {
          notify("error", err.response.data.message);
        }
      }
    });
}

export async function updateUser(data, callback) {
  axios({
    method: "PATCH",
    url: server + "api/updateUser",
    data: data,
    withCredentials: true,
  })
    .then((res) => {
      if (res.data.status) {
        notify(res.data.status, res.data.message);
        callback(res.data.status);
      }
    })
    .catch((err) => {
      callback("failed");
      console.log(err);
      if (err.response) {
        if (err.response.data.message) {
          notify("error", err.response.data.message);
        }
      }
    });
}

export async function updateUserPassword(
  email,
  password,
  cpassword,
  oldPassword,
  callback
) {
  axios({
    method: "PATCH",
    url: server + "api/updatePassword",
    data: {
      email: email,
      password: password,
      cpassword: cpassword,
      oldPassword: oldPassword,
    },
    withCredentials: true,
  })
    .then((res) => {
      if (res.data.status) {
        notify(res.data.status, res.data.message);
        callback(res.data.status);
        return;
      }
    })
    .catch((err) => {
      callback("failed");
      if (err.response) {
        if (err.response.data.message) {
          notify("error", err.response.data.message);
        }
      }
    });
}

export async function forgotPassword(email, callback) {
  axios({
    method: "POST",
    url: server + "api/requestResetPassword",
    data: {
      email: email,
    },
    withCredentials: true,
  })
    .then((res) => {
      if (res.data.status) {
        notify(res.data.status, res.data.message);
        callback(res.data.status);
        return;
      }
    })
    .catch((err) => {
      callback("failed");
      if (err.response) {
        if (err.response.data.message) {
          notify("error", err.response.data.message);
        }
      }
    });
}

export async function ResetNewPassword(data, callback) {
  axios({
    method: "POST",
    url: server + "api/resetPassword",
    data: {
      email: data.email,
      password: data.password,
      cpassword: data.cpassword,
      token: data.token,
      id: data.id,
    },
    headers: {
      authorization: `bearer ${data.token}`,
    },
    withCredentials: true,
  })
    .then((res) => {
      if (res.data.status) {
        notify(res.data.status, res.data.message);
        callback(res.data.status);
        return;
      }
    })
    .catch((err) => {
      callback("failed");
      if (err.response) {
        if (err.response.data.message) {
          notify("error", err.response.data.message);
        }
      }
    });
}

export async function deleteUser(email) {
  axios({
    method: "DELETE",
    url: server + "api/deleteUser",
    data: {
      email: email,
    },
    withCredentials: true,
  })
    .then((res) => {
      if (res.data.status) {
        if (res.data.status === "success") {
          userLogout();
        }
        return;
      }
    })
    .catch((err) => {
      console.log(err);
      if (err.response) {
        if (err.response.data.message) {
          notify("error", err.response.data.message);
        }
      }
    });
}

export async function checkLogin(callback) {
  axios({
    method: "GET",
    url: server + "api/checkLogin",
    withCredentials: true,
  })
    .then((res) => {
      callback(res.data);
    })
    .catch((err) => {
      callback("failed");
      /* if(err.response){
            callback(err.response.statusText)
        } */
    });
}

export async function updateUserPermission(data, callback) {
  axios({
    method: "PATCH",
    url: server + "api/updateUserPermissions",
    withCredentials: true,
    data: data,
  })
    .then((res) => {
      if (res.data.status === "success") {
        notify(res.data.status, res.data.message);
        callback(res.data.status);
      }
    })
    .catch((err) => {
      callback("failed");
      if (err.response) {
        if (err.response.data.message) {
          notify("error", err.response.data.message);
        }
      }
    });
}

export async function generateAPIToken(callback) {
  let user = window.localStorage.getItem("gas-web-user");

  if (!user) {
    return;
  }

  axios({
    method: "POST",
    url: server + "api/generateAPIToken",
    withCredentials: true,
  })
    .then((res) => {
      if (!res.data.token) {
        notify("error", "No records");
        return;
      }

      notify(res.data.status, res.data.message);
      callback(res.data.token);
    })
    .catch((err) => {
      if (err.response) {
        if (err.response.data.message) {
          notify("error", err.response.data.message);
          if (err.response.data.message === "Auth Failed") {
            userLogout();
          }
        }
      }
    });
}

export async function getAPIToken(callback) {
  let user = window.localStorage.getItem("gas-web-user");

  if (!user) {
    return;
  }

  axios({
    method: "GET",
    url: server + "api/getAPIToken",
    withCredentials: true,
  })
    .then((res) => {
      if (!res.data.token) {
        return;
      }

      callback(res.data.token);
    })
    .catch((err) => {
      if (err.response) {
        if (err.response.data.message) {
          notify("error", err.response.data.message);
          if (err.response.data.message === "Auth Failed") {
            userLogout();
          }
        }
      }
    });
}