import "react-big-calendar/lib/css/react-big-calendar.css";
import "./scss/App.scss";
import "react-notifications/lib/notifications.css";
import React, { useEffect, useState, lazy, Suspense } from "react";
import { Route, Redirect, Switch, useLocation } from "react-router-dom";
import { NotificationContainer } from "react-notifications";
import Config from "./Config";
import { checkLogin, getUser } from "./functions/api/user";
import { getWhiteLabel } from "./functions/api/whiteLabel";
// import Drift from "react-driftjs";
import 'devextreme/dist/css/dx.light.css';
import Navbar from "./components/Layout/Navbar";
import PreLoading from "./components/PreLoading";
import Loading from "./components/Loading";
import ConnectionBackDrop from "./components/ConnectionBackDrop";
import useDisablePasswordAutofill from "./customhooks/useDisablePasswordAutofill";


const componentLoader = (lazyComponent, attemptsLeft = 5, interval = 2000) => {
  return new Promise((resolve, reject) => {
    lazyComponent()
      .then(resolve)
      .catch((error) => {
        setTimeout(() => {
          if (attemptsLeft === 1) {
            reject(error);
            return;
          }
          componentLoader(lazyComponent, attemptsLeft - 1, interval).then(
            resolve,
            reject
          );
        }, interval);
      });
  });
};

const Login = lazy(() => import("./pages/Login"));
const Register = lazy(() => import("./pages/Register"));
const ForgotPassword = lazy(() => import("./pages/ForgotPassword"));
const ResetPassword = lazy(() => import("./pages/ResetPassword"));
const BackDrop = lazy(() => import("./components/BackDrop"));

const Devices = lazy(() => import("./pages/Devices"));
// my Data source path
const DataSource = lazy(() => import("./pages/MyDataSource"));
const Home = lazy(() => import("./pages/Home"))
//My Dash Board Page
const MyNewDashBOard = lazy(() => import("./pages/MyDashBoard"));
const Members = lazy(() => import("./pages/Members"));
const Workspace = lazy(() => import("./pages/Workspace"));
const Device = lazy(() => import("./pages/Device"));
const Profile = lazy(() => import("./pages/Profile"));
const Configure = lazy(() => import("./pages/Configure"));
const AcceptWorkspace = lazy(() => import("./pages/AcceptWorkspace"));
const Notifications = lazy(() => import("./pages/Notifications"));
const AddNotification = lazy(() => import("./pages/AddNotification"));
const EditNotification = lazy(() => import("./pages/EditNotification"));
const Reports = lazy(() => import("./pages/Reports"));
const AddReport = lazy(() => import("./pages/AddReport"));
const EditReport = lazy(() => import("./pages/EditReport"));
const WhiteLabel = lazy(() => import("./pages/WhiteLabel"));
const Checkout = lazy(() => import("./pages/Checkout"));
const GlobalDashboard = lazy(() => import("./pages/GlobalDashboard"));
const Billing = lazy(() => import("./pages/Billing"));
const OpenDashboard = lazy(() => import("./pages/OpenDashboard"));
const Notebook = lazy(() => import("./pages/Notebook"));
const Calendar = lazy(() => import("./pages/Calendar"));
const CalendarEvent = lazy(() => import("./pages/CalendarEvent"));
const Cellular = lazy(() => import("./pages/Cellular"));
const CellularSim = lazy(() => import("./pages/CellularSim"));
const TestPage = lazy(() => import("./pages/TestPage"));
const EmailVerification = lazy(() => import("./pages/EmailVerification"));

const NotFound = lazy(() => import("./pages/NotFound"));

export var exportSetWorspacePermission;
export var exportSuccessLogin;
export var exportSetBackDropState;

function App() {
  const location = useLocation();
  const [SpinnerState, setSpinnerState] = useState(true);
  const [BackDropState, setBackDropState] = useState(false);
  const [WhiteLabelPaid, setWhiteLabelPaid] = useState(true);
  const [ConnectionBackDropState, setConnectionBackDropState] = useState(false);
  const [UserWorkspacePermission, setUserWorkspacePermission] = useState({
    addDevices: false,
    deviceConfigEdit: false,
    layoutEdit: false,
    widgetsEdit: false,
    notificationEdit: false,
    reportEdit: false,
    notebookEdit: false,
    cellularEdit: false,
  });
  const [UserPermission, setUserPermission] = useState({
    workspaceEdit: false,
    mqttConfigEdit: false,
  });
  const [WhiteLabelDisplay, setWhiteLabelDisplay] = useState(false);
  let pathname = location.pathname.split("/")[1];
  
  useDisablePasswordAutofill();

  useEffect(() => {
    if (Config.development === "true") {
      var Tawk_API = Tawk_API || {},
        Tawk_LoadStart = new Date();
      (function () {
        var s1 = document.createElement("script"),
          s0 = document.getElementsByTagName("script")[0];
        s1.async = true;
        s1.src = Config.tawk;
        s1.charset = "UTF-8";
        s1.setAttribute("crossorigin", "*");
        s0.parentNode.insertBefore(s1, s0);
      })();
    }
  }, []);

  useEffect(() => {
    let spinner = document.getElementById("preLoader");

    if (SpinnerState) {
      spinner.style.display = "flex";
      spinner.style.opacity = 1;
    } else {
      setTimeout(() => {
        spinner.style.opacity = 0;
      }, 500);

      setTimeout(() => {
        spinner.style.display = "none";
      }, 650);
    }
  }, [SpinnerState]);

  const [LoggedIn, setLogin] = useState(false);

  let loginStatus = window.localStorage.getItem("gas-web-login");
  let user = window.localStorage.getItem("gas-web-user");
  let access = window.localStorage.getItem("gas-web-access");

  exportSuccessLogin = () => {
    setLogin(true);
  };

  useEffect(() => {
    checkLogin((data) => {
      if (data.status === "failed") {
        setLogin(false);
      } else {
        user = data.user;
        access = data.access;
        if (user) {
          loginStatus = data.login;
          window.localStorage.setItem("gas-web-login", loginStatus);
          window.localStorage.setItem("gas-web-user", user);
          window.localStorage.setItem("gas-web-access", access);
        }
        setLogin(loginStatus);
      }
    });

    getUser((data) => {
      if (data) setUserPermission(data.data.permission);
    });

    if (
      window.localStorage.getItem("gas-web-state") === false ||
      window.localStorage.getItem("gas-web-state") === "false"
    ) {
      let expiresIn = window.localStorage.getItem("gas-web-expires");

      setInterval(() => {
        let d = new Date();
        let now = d.getTime();

        if (now >= parseInt(expiresIn)) {
          window.localStorage.removeItem("gas-web-login");
          window.localStorage.removeItem("gas-web-user");
          window.localStorage.removeItem("gas-web-id");
          window.localStorage.removeItem("gas-web-role");
          window.localStorage.removeItem("gas-web-state");
          window.localStorage.removeItem("gas-web-access");
          window.localStorage.removeItem("gas-web-expires");
          window.localStorage.removeItem("workspace");
          window.location.assign(window.location.origin);
        }
      }, 1000);
    }

    setSpinnerState(false);
  }, [LoggedIn, user, loginStatus]);

  exportSetWorspacePermission = (data) => {
    setUserWorkspacePermission(data);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  function setSiteProperties() {
    let domain = window.location.hostname;

    getWhiteLabel(domain, (data) => {
      if (data) {
        if (data.paid) setWhiteLabelPaid(data.paid);

        if (data.siteTitle) {
          document.title = data.siteTitle;
        } else {
          document.title = "Industrial Dashboard";
        }

        //loginTheme
        if (data.loginTheme) {
          let theme = data.loginTheme

          const root = document.documentElement;
          root?.style.setProperty("--background-color",theme === "dark" ? "#000000" : "#ffffff");
          root?.style.setProperty("--text-color", theme === "dark" ? "#ffffff" : "#000000");
          root?.style.setProperty("--forgotPwd", theme === "dark" ? "#6c757d" : "#000000");
          root?.style.setProperty("--loginBtn", theme === "dark" ? "#ffffff" : "#4AA4EB");
        }

        //icon
        let linkIcon = document.querySelector("link[rel~='icon']");
        if (!linkIcon) {
          linkIcon = document.createElement("link");
          linkIcon.rel = "icon";
          document.getElementsByTagName("head")[0].appendChild(linkIcon);
        }

        //apple icon
        let linkAppleIcon = document.querySelector(
          "link[rel~='apple-touch-icon']"
        );
        if (!linkAppleIcon) {
          linkAppleIcon = document.createElement("link");
          linkAppleIcon.rel = "icon";
          document.getElementsByTagName("head")[0].appendChild(linkAppleIcon);
        }

        if (data.siteIcon) {
          linkAppleIcon.href = linkIcon.href = Config.server + data.siteIcon;
        } else {
          linkAppleIcon.href = linkIcon.href = "/logo.png";
        }

        let myDynamicManifest = {
          short_name: data.siteTitle || "Industrial Dashboard",
          name: data.siteTitle || "Industrial Dashboard",
          icons: [
            {
              src: data.siteIcon
                ? Config.server + data.siteIcon
                : "favicon.ico",
              sizes: "64x64 32x32 24x24 16x16",
            },
            {
              src: data.siteIcon
                ? Config.server + data.siteIcon
                : "logo192.png",
              sizes: "192x192",
            },
            {
              src: data.siteIcon
                ? Config.server + data.siteIcon
                : "logo512.png",
              sizes: "512x512",
            },
          ],
          start_url: ".",
          display: "standalone",
          theme_color: data.siteColor ? `#${data.siteColor}` : "#423F3E",
          background_color: "#ffffff",
        };

        const stringManifest = JSON.stringify(myDynamicManifest);
        const blob = new Blob([stringManifest], { type: "application/json" });
        const manifestURL = URL.createObjectURL(blob);
        document
          .querySelector("#app-mainfest")
          .setAttribute("href", manifestURL);
      } 
    });
  }

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      if (Config.development !== "true") {
        window.addEventListener(
          "online",
          function (e) {
            setConnectionBackDropState(false);
          },
          false
        );

        window.addEventListener(
          "offline",
          function (e) {
            setConnectionBackDropState(true);
          },
          false
        );
      }

      if (window.origin === Config.origin) {
        setWhiteLabelDisplay(true);
        // setWhiteLabelPaid(true);
      } else {
        // setWhiteLabelPaid(false);
        setWhiteLabelDisplay(false);
      }

      setSiteProperties();
    }

    return () => {
      isMounted = false;
      window.removeEventListener(
        "online",
        function (e) {
          setConnectionBackDropState(false);
        },
        false
      );

      window.removeEventListener(
        "offline",
        function (e) {
          setConnectionBackDropState(true);
        },
        false
      );
    };
  }, []);

  exportSetBackDropState = (state) => {
    setBackDropState(state || false);
  };

  return (
    <div id="app">
      <ConnectionBackDrop open={ConnectionBackDropState} />
      <Switch>
        {!WhiteLabelPaid ? (
          <div className="serviceNotAvailable">
            <p>Service not available</p>
          </div>
        ) : LoggedIn ? (
          <>
            <Navbar />
            <Suspense
              fallback={
                <div className="container loader">
                  <Loading />
                </div>
              }
            >
              <BackDrop open={BackDropState} />
              <Route exact path="/">
                {" "}
                <Redirect to="/home" />{" "}
              </Route>
              <Route path="/home" component={Home} />
              {/* <Route path="/home" component={GlobalDashboard} /> */}
              <Route path="/my-data-sources" component={DataSource} />
              <Route path="/my-dashboard" component={MyNewDashBOard} />
              <Route path="/devices" component={Devices} />
              <Route path="/device" component={Device} />
              <Route path="/profile" component={Profile} />
              <Route path="/configure" component={Configure} />
              <Route path="/accept" component={AcceptWorkspace} />
              <Route path="/checkout" component={Checkout} />
              <Route path="/billing" component={Billing} />
              <Route path="/calendar" component={Calendar} />
              <Route path="/calendar-event" component={CalendarEvent} />
              <Route path="/open-dashboard" component={OpenDashboard} />
              <Route path="/notebook" component={Notebook} />
              <Route path="/email-verification" component={EmailVerification} />
              {WhiteLabelDisplay ? (
                <Route path="/white-label" component={WhiteLabel} />
              ) : null}
              <Route path="/members" component={Members} />
              <Route path="/workspace" component={Workspace} />
              {UserWorkspacePermission.cellularEdit ? (
                <Route path="/cellular" component={Cellular} />
              ) : null}
              {UserWorkspacePermission.cellularEdit ? (
                <Route path="/cellular-sim" component={CellularSim} />
              ) : null}
              {UserWorkspacePermission.notificationEdit ? (
                <Route path="/notifications" component={Notifications} />
              ) : null}
              {UserWorkspacePermission.notificationEdit ? (
                <Route path="/new-notification" component={AddNotification} />
              ) : null}
              {UserWorkspacePermission.notificationEdit ? (
                <Route path="/edit-notification" component={EditNotification} />
              ) : null}
              {UserWorkspacePermission.reportEdit ? (
                <Route path="/reports" component={Reports} />
              ) : null}
              {UserWorkspacePermission.reportEdit ? (
                <Route path="/new-report" component={AddReport} />
              ) : null}
              {UserWorkspacePermission.reportEdit ? (
                <Route path="/edit-report" component={EditReport} />
              ) : null}
              {/* <Route> <Redirect to="/home" /> </Route> */}
            </Suspense>
          </>
        ) : (
          <Suspense fallback={<PreLoading />}>
            <Route path="/test-page" component={TestPage} />
            <Route path="/register" component={Register} />
            <Route path="/forgotpassword" component={ForgotPassword} />
            <Route path="/resetPassword" component={ResetPassword} />
            <Route component={Login} />
            
          </Suspense>
        )}
      </Switch>
      <NotificationContainer />
    </div>
  );
}

export default App;
