require('dotenv').config()

const {
    REACT_APP_SERVER,
    REACT_APP_SOCKET,
    REACT_APP_SCRIPT_SERVER,
    REACT_APP_ORIGIN_URL,
    REACT_APP_STRIPE_PK,
    REACT_APP_DEVELOPMENT,
    REACT_APP_MAP_KEY,
    REACT_APP_TESTING,
    REACT_APP_TAWK_SRC,
    REACT_APP_DRIFT_APPID,
    REACT_APP_CLOUD_SERVER
} = process.env

module.exports = {
    server: REACT_APP_SERVER,
    cloud_server: REACT_APP_CLOUD_SERVER,
    socket: REACT_APP_SOCKET,
    script_server: REACT_APP_SCRIPT_SERVER,
    origin: REACT_APP_ORIGIN_URL,
    stripe_pk: REACT_APP_STRIPE_PK,
    map_key: REACT_APP_MAP_KEY,
    development: REACT_APP_DEVELOPMENT,
    testing: REACT_APP_TESTING,
    tawk: REACT_APP_TAWK_SRC,
    drift: REACT_APP_DRIFT_APPID,
}