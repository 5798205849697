import React from 'react'
import Backdrop from '@material-ui/core/Backdrop';
import { Typography } from '@material-ui/core';
import { TiWarning } from 'react-icons/ti'

export default function ConnectionBackDrop({open}) {

    return (
        <div className="connectionBackDrop">
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={open}
                className="backDrop_content"
            >
                <Typography style={{color: '#fff', fontSize: '2rem'}} > <TiWarning/> </Typography>
                <Typography style={{color: '#fff'}} >Seems That You're Offline </Typography>
            </Backdrop>
        </div>
    )
}
