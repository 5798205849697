import { server } from "../../Config";
import { notify } from "../notify";
import axios from "axios";

export async function getUserConfig(callback) {
  let user = window.localStorage.getItem("gas-web-user");

  if (!user) {
    return;
  }

  axios({
    method: "GET",
    url: server + "api/getUserConfig/" + user,
    withCredentials: true,
  })
    .then((res) => {
      if (!res.data.data) {
        notify("info", "No records");
        return;
      }

      if (callback) {
        callback(res.data.data);
      }
    })
    .catch((err) => {
      console.log(err);
      if (err.response) {
        /* if(err.response.data.message){
                notify('error', err.response.data.message)
            } */
      }
    });
}

export async function getUserConfigGroupForWorkspace(data, callback) {
  axios({
    method: "POST",
    url: server + "api/getUserConfigWorkspaceGroup",
    data: data,
    withCredentials: true,
  })
    .then((res) => {
      if (!res.data.data) {
        notify("info", "No records");
        return;
      }

      if (callback) {
        callback(res.data.data);
      }
    })
    .catch((err) => {
      if (err.response) {
        if (err.response.data.message) {
          notify("error", err.response.data.message);
        }
      }
    });
}

export async function getUserConfigForWorkspace(id, callback) {
  let user = window.localStorage.getItem("gas-web-user");

  axios({
    method: "POST",
    url: server + "api/getUserConfigWorkspace",
    data: {
      email: user,
      workspace: id,
    },
    withCredentials: true,
  })
    .then((res) => {
      if (!res.data.data) {
        notify("info", "No records");
        return;
      }

      if (callback) {
        callback(res.data.data);
      }
    })
    .catch((err) => {
      if (err.response) {
        if (err.response.data.message) {
          notify("error", err.response.data.message);
        }
      }
    });
}

export async function findUserConfigGroupForWorkspace(data, callback) {
  axios({
    method: "POST",
    url: server + "api/findUserConfigWorkspaceGroup",
    data: data,
    withCredentials: true,
  })
    .then((res) => {
      if (!res.data.data) {
        notify("info", "No records");
        return;
      }

      if (callback) {
        callback(res.data.data);
      }
    })
    .catch((err) => {
      if (err.response) {
        if (err.response.data.message) {
          notify("error", err.response.data.message);
        }
      }
    });
}

export async function updateUserWorkspacePermission(data, callback) {
  axios({
    method: "PATCH",
    url: server + "api/updateUserConfigPermission",
    withCredentials: true,
    data: data,
  })
    .then((res) => {
      if (res.data.status === "success") {
        notify(res.data.status, res.data.message);
        callback(res.data.status);
      }
    })
    .catch((err) => {
      if (err.response) {
        if (err.response.data.message) {
          notify("error", err.response.data.message);
        }
      }
    });
}

export async function deleteUserConfig(id, callback) {
  axios({
    method: "DELETE",
    url: server + "api/removeUserConfig",
    withCredentials: true,
    data: {
      id: id,
    },
  })
    .then((res) => {
      if (res.data.status === "success") {
        notify(res.data.status, res.data.message);
        callback(res.data.status);
      }
    })
    .catch((err) => {
      if (err.response) {
        if (err.response.data.message) {
          notify("error", err.response.data.message);
        }
      }
    });
}

export async function sendInviteToUser(data, callback) {
  axios({
    method: "POST",
    url: server + "api/sendInviteToUser",
    withCredentials: true,
    data: data,
  })
    .then((res) => {
      if (res.data.status === "success") {
        notify(res.data.status, res.data.message);
        callback(res.data.status);
      }
    })
    .catch((err) => {
      console.log(err.response)
      if (err.response) {
        if (err.response.data.message) {
          notify("error", err.response.data.message);
        }
        callback("failed");
      } else {
        notify("error", "Error");
        callback("failed");
      }
    });
}

export async function addWorkspace(data, callback) {
  let user = window.localStorage.getItem("gas-web-user");

  axios({
    method: "POST",
    url: server + "api/addUserConfigToWorkspace",
    withCredentials: true,
    data: {
      email: user,
      workspace: data.workspace,
      groupId: data.groupId,
    },
  })
    .then((res) => {
      callback(res.data.status);
    })
    .catch((err) => {
      console.log(err);
      callback("failed");
      if (err.response) {
        if (err.response.data.message) {
          notify("error", err.response.data.message);
        }
      }
    });
}
