import { server } from "../../Config";
import { notify } from "../notify";
import axios from "axios";

export async function createWorkspace(name, callback) {
  let user = window.localStorage.getItem("gas-web-user");

  axios({
    method: "POST",
    url: server + "api/addWorkspace",
    withCredentials: true,
    data: {
      name: name,
      email: user,
    },
  })
    .then((res) => {
      notify(res.data.status, res.data.message);
      callback(res.data.status);
    })
    .catch((err) => {
      console.log(err);
      if (err.response) {
        if (err.response.data.message) {
          notify("error", err.response.data.message);
        }
      }
    });
}

export async function getWorkspace(id, callback) {
  let emptyVals = ["undefined", "false", undefined, null, false, NaN, ""];

  if (!emptyVals.includes(id)) {
    axios({
      method: "GET",
      url: server + "api/getWorkspace/" + id,
      withCredentials: true,
    })
      .then((res) => {
        callback(res.data.data);
      })
      .catch((err) => {
        console.log(err);
        if (err.response) {
          if (err.response.data.message) {
            notify("error", err.response.data.message);
          }
        }
      });
  }
}

export async function updateWorkspace(data, callback) {
  axios({
    method: "PATCH",
    url: server + "api/updateWorkspace",
    withCredentials: true,
    data: data,
  })
    .then((res) => {
      if (res.data.status === "success") {
        notify(res.data.status, res.data.message);
        callback(res.data.status);
      }
    })
    .catch((err) => {
      if (err.response) {
        if (err.response.data.message) {
          notify("error", err.response.data.message);
        }
      }
    });
}

export async function updateWorkspaceLogo(id, image, callback) {
  let data = new FormData();

  data.append("id", id);
  data.append("image", image.files[0]);

  axios({
    method: "PATCH",
    url: server + "api/updateWorkspaceImage",
    withCredentials: true,
    data: data,
  })
    .then((res) => {
      if (res.data.status === "success") {
        notify(res.data.status, res.data.message);
        callback(res.data.status);
      }
    })
    .catch((err) => {
      if (err.response) {
        if (err.response.data.message) {
          notify("error", err.response.data.message);
        }
      }
    });
}

export async function deleteWorkspace(id, callback) {
  axios({
    method: "DELETE",
    url: server + "api/deleteWorkspace",
    data: {
      id: id,
    },
    withCredentials: true,
  })
    .then((res) => {
      callback(res.data.status);
      if (res.data.status) {
        notify(res.data.status, res.data.message);
        return;
      }
    })
    .catch((err) => {
      console.log(err);
      if (err.response) {
        if (err.response.data.message) {
          notify("error", err.response.data.message);
        }
      }
    });
}
