import { server } from "../../Config";
import { notify } from "../notify";
import axios from "axios";

export async function userLogout(pathname="/") {
  let state = window.localStorage.getItem("gas-web-state");
  let token = window.localStorage.getItem("gas-web-access");

  if (state === "true" || state === true) {
    axios({
      method: "POST",
      url: server + "api/logout",
      withCredentials: true,
      data: {
        token: token,
      },
    })
      .then((res) => {
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.data.message) {
            notify("error", err.response.data.message);
          }
        } else {
          notify("error", "Error");
        }
      });
  } else {
    axios({
      method: "POST",
      url: server + "api/logout",
      withCredentials: true,
      data: {
        token: token,
      },
    })
      .then((res) => {
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.data.message) {
            notify("error", err.response.data.message);
          }
        } else {
          notify("error", "Error");
        }
      });
  }

  window.localStorage.removeItem("gas-web-login");
  window.localStorage.removeItem("gas-web-user");
  window.localStorage.removeItem("gas-web-id");
  window.localStorage.removeItem("gas-web-role");
  window.localStorage.removeItem("gas-web-state");
  window.localStorage.removeItem("gas-web-access");
  window.localStorage.removeItem("gas-web-expires");
  window.localStorage.removeItem("workspace");
  setTimeout(() => {
    window.location.assign(`${pathname}`);
  }, 1500);
}
