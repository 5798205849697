import Config, { server } from "../../Config";
import { notify } from "../notify";
import axios from "axios";

export async function updateSiteLogo(id, image, callback) {
  let data = new FormData();

  data.append("id", id);
  data.append("image", image.files[0]);

  axios({
    method: "PATCH",
    url: server + "api/updateSiteLogo",
    data: data,
    withCredentials: true,
  })
    .then((res) => {
      callback(res.data.status);
      if (res.data.status === "success") {
        notify(res.data.status, res.data.message);
        return;
      }
    })
    .catch((err) => {
      callback("failed");
      console.log(err);
      if (err.response) {
        if (err.response.data.message) {
          notify("error", err.response.data.message);
        }
      }
    });
}

export async function updateSiteIcon(id, image, callback) {
  let data = new FormData();

  data.append("id", id);
  data.append("image", image.files[0]);

  axios({
    method: "PATCH",
    url: server + "api/updateSiteIcon",
    data: data,
    withCredentials: true,
  })
    .then((res) => {
      callback(res.data.status);
      if (res.data.status === "success") {
        notify(res.data.status, res.data.message);
        return;
      }
    })
    .catch((err) => {
      callback("failed");
      console.log(err);
      if (err.response) {
        if (err.response.data.message) {
          notify("error", err.response.data.message);
        }
      }
    });
}

export async function updateWhiteLabel(data, callback) {
  axios({
    method: "PATCH",
    url: server + "api/updateWhiteLabel",
    withCredentials: true,
    data: data,
  })
    .then((res) => {
      if (res.data.status === "success") {
        notify(res.data.status, res.data.message);
        callback(res.data.status);
      }
    })
    .catch((err) => {
      callback("failed");
      if (err.response) {
        if (err.response.data.message) {
          notify("error", err.response.data.message);
        }
      }
    });
}

export async function createWhiteLabel(data, callback) {
  axios({
    method: "POST",
    url: server + "api/addWhiteLabel",
    withCredentials: true,
    data: data,
  })
    .then((res) => {
      if (res.data.status === "success") {
        notify(res.data.status, res.data.message);
        callback(res.data.status);
      }
    })
    .catch((err) => {
      callback("failed");
      if (err.response) {
        if (err.response.data.message) {
          notify("error", err.response.data.message);
        }
      }
    });
}

export async function verifiyMail(data, callback) {
  axios({
    method: "POST",
    url: server + "api/sendVerificationMail",
    withCredentials: true,
    data: data,
  })
    .then((res) => {
      notify(res.data.status, res.data.message);
      callback(res.data);
    })
    .catch((err) => {
      callback("failed");
      if (err.response) {
        if (err.response.data.message) {
          notify("error", err.response.data.message);
        }
      }
    });
}

export async function verifyEmailToken(token, callback) {
  axios({
    method: "GET",
    url: server + "api/verifyMail/" + token,
    withCredentials: true,
  })
    .then((res) => {
      notify(res.data.status, res.data.message);
      callback(res.data.status);
    })
    .catch((err) => {
      console.log(err);
      if (err.response) {
        if (err.response.data.message) {
          notify("error", err.response.data.message);
        }
      }
    });
}

export async function getWhiteLabel(id, callback) {
  axios({
    method: "GET",
    url: server + "api/getWhiteLabel/" + id,
    withCredentials: true,
  })
    .then((res) => {
      if (res.data.status === "success") {
        callback(res.data.data);
      }
    })
    .catch((err) => {
      console.log(err);
      if (err.response) {
        if (err.response.data.message) {
          notify("error", err.response.data.message);
        }
      }
    });
}

export async function getWhiteLabelByUser(callback) {
  let user = window.localStorage.getItem("gas-web-user");

  axios({
    method: "GET",
    url: server + "api/getWhiteLabelByUser/" + user,
    withCredentials: true,
  })
    .then((res) => {
      if (res.data.status === "success") {
        callback(res.data.data);
      }
    })
    .catch((err) => {
      console.log(err);
      if (err.response) {
        if (err.response.data.message) {
          notify("error", err.response.data.message);
        }
      }
    });
}

export async function deleteWhiteLabel(id, callback) {
  axios({
    method: "DELETE",
    url: server + "api/deleteWhiteLabel",
    withCredentials: true,
    data: {
      id: id,
    },
  })
    .then((res) => {
      if (res.data.status === "success") {
        notify(res.data.status, res.data.message);
        callback(res.data.status);
      }
    })
    .catch((err) => {
      if (err.response) {
        if (err.response.data.message) {
          notify("error", err.response.data.message);
        }
      }
    });
}

export async function RunSSLInstaller(domain) {
  axios({
    method: "POST",
    url: Config.script_server + "addSSL",
    data: {
      domain: domain,
    },
  })
    .then((res) => {
      //
    })
    .catch((err) => {
      console.log(err);
      if (err.response) {
        if (err.response.data.message) {
          notify("error", err.response.data.message);
        }
      }
    });
}

export async function RemoveSSL(domain) {
  axios({
    method: "POST",
    url: Config.script_server + "deleteSSL",
    data: {
      domain: domain,
    },
  })
    .then((res) => {
      //
    })
    .catch((err) => {
      console.log(err);
      if (err.response) {
        if (err.response.data.message) {
          notify("error", err.response.data.message);
        }
      }
    });
}

export function DNSLookUpIp(domain, callback) {
  axios({
    method: "POST",
    url: server + "api/getDNSLookUp",
    data: {
      domain: domain,
    },
    withCredentials: true,
  })
    .then((res) => {
      callback(res.data.data);
    })
    .catch((err) => {
      console.log(err);
      if (err.response) {
        if (err.response.data.message) {
          notify("error", err.response.data.message);
        }
      }
    });
}
