import React from 'react'

function DeviceNotPaid({onClick}) {

    function handleOnClick() {
        if(onClick){
            onClick(false)
        }
    }

    return (
        <div className="delete_propup" >
            {/* <p className="dp_title">Your payment for this device has expired.</p> */}
            <p className="dp_title">Your payment for this data Sourse has expired.</p>
            <div className="dp_button_wrap">
                <button className="button" onClick={() => handleOnClick()} >OK</button>
            </div>
        </div>
    )
}

export default DeviceNotPaid
