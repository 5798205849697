import { NotificationManager } from "react-notifications";
import { userLogout } from "./api/logout";

export async function notify(type, message, title) {
  if (message) {
    message = message.toString();
    if (
      String(message).trim() === String("Auth failed").trim() ||
      String(message).trim().toLowerCase() ===
        String("Token Must Be a String").trim().toLowerCase()
    ) {
      userLogout();
    } else if (
      String(message).trim().toLowerCase() ===
        String("Auth failed").trim().toLowerCase() ||
      String(message).trim().toLowerCase() ===
        String("Token Must Be a String").trim().toLowerCase()
    ) {
      userLogout();
    } else {
      switch (type) {
        case "info":
          NotificationManager.info(message);
          break;
        case "success":
          NotificationManager.success(message, title);
          break;
        case "warning":
          NotificationManager.warning(message, title);
          break;
        case "failed":
          NotificationManager.error(message, title);
          break;
        case "error":
          NotificationManager.error(message, title);
          break;
        default:
          break;
      }
    }
    message = capitalizeTxt(message);
  }  
  function capitalizeTxt(txt) {
    return txt.charAt(0).toUpperCase() + txt.slice(1).toLowerCase(); //or if you want lowercase the rest txt.slice(1).toLowerCase();
  }
}
